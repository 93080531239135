
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AutenticacaoService } from '../services/autenticacao.service';
import { enumTransacao } from '../domains/enumtransacao.enum';
import { AssinadorService } from '../services/assinador.service';
import { ConfiguracaoAssinador } from '../entities/configuracaoassinador.entity';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"]
})
export class NavComponent implements OnInit {
  constructor(
    private router: Router,
    public authService: AutenticacaoService,
    private assinadorSrv: AssinadorService
  ) {}

  public configuracaoAssinador: ConfiguracaoAssinador = new ConfiguracaoAssinador;
  public transacoes = enumTransacao;
  public ExtensaoInstalada: boolean = true;
  public AssinadorInstalado: boolean = true;
  public MensagemAssinador: string = "";
  dadosToken: any = new Object();
  ngOnInit() {
    this.authService.RetornarConfiguracaoAssinador().subscribe(d=>
      {
        this.configuracaoAssinador = d;
      });
      this.VerificarAssinador();
  }

  public VerificarAssinador()
  {
    window.setTimeout(()=>
    {
    this.ExtensaoInstalada = this.assinadorSrv.VerificarExtensao();
    if (this.ExtensaoInstalada)
    {
      this.assinadorSrv.VerificarAssinador().then(()=>
      {
        this.AssinadorInstalado=true;

      }).catch(e=>
        {
          this.AssinadorInstalado = false;
          this.MensagemAssinador= e;

        });

    }
  },1000)
  }

  logout() {
    this.authService.LogOut().then(()=> {
        this.authService.Logado = false;
        sessionStorage.removeItem('TokenRegistImoveis');
        this.router.navigate(["/login"]);
    })

  }
  loggedIn() {
   
    return this.authService.Logado;
  }

  alterarSenha(): void
  {
    this.router.navigate(["/alterarsenha"]);

  }

  VerificaPermissao(transacao: number): boolean {
    return this.authService.Acessos.find(d => d == transacao) != null;
  }

  RedirecionarInstalador(): void
  {
    if (!this.AssinadorInstalado)
    {
      window.open(this.configuracaoAssinador.URLAssinador,"_blank");
    }
    else
      window.open(this.configuracaoAssinador.URLExtensao,"_blank");

  }
  
}
