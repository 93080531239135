import { Observable,throwError, of} from "rxjs";
import { Injectable } from "@angular/core";
import { HttpInterceptor } from "@angular/common/http";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpEvent } from "@angular/common/http";
import { HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { catchError } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { AlertifyService } from './alertify.service';
import { TranslateService } from '../translate';

@Injectable()
export class AutenticacaoInterceptor implements HttpInterceptor {
  constructor(private router: Router, private alertify: AlertifyService, private _translate: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("TokenRegistImoveis");
    request = request.clone({
      withCredentials: true
  });
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    
    if (token && request.url.indexOf("ValidarToken") < 0) {
      headerSettings["Authorization"] = "Bearer " + token;
    }
    headerSettings["Content-Type"] = "application/json";

 
    const newHeader = new HttpHeaders(headerSettings);
    const dupReq = request.clone({
      headers: newHeader
    });
    return next.handle(dupReq)
    .pipe(
      catchError(error => {
        
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) { 
            sessionStorage.removeItem("TokenRegistImoveis");
            this.router.navigate(['login']);
            return of(error as any);
           }
          const applicationError = error.headers.get("Application-Error");
          if (applicationError) {
            this.alertify.error(this._translate.instant("ErroInesperado"));
            return throwError(applicationError);
          }
          else if (error.status === 500) { 
            this.alertify.error(this._translate.instant("ErroInesperado"));
          }
          const serverError = error.error;
          let modalStateErrors = "";
          if (serverError && typeof serverError === "object") {
            for (const key in serverError) {
              if (serverError[key]) {
                modalStateErrors += serverError[key] + "\n";
              }
            }
          }
          return throwError(modalStateErrors || serverError || "Server Error");
        }
      })
    );
    
  }

}
