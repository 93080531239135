import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from '../services/autenticacao.service'
import { TranslateService } from '../translate';
import { Router, ActivatedRoute } from '@angular/router';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity';
import { DadoAutenticacao } from '../entities/dadoautenticacao.entity';
import { ConfigService } from '@ngx-config/core';
import { Mensagem} from '../entities/mensagem.entity';
import { ValidacaoUsuario } from '../entities/validacaousuario.entity';

@Component({
    moduleId: module.id,
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


    public Erros: Mensagem[] = [];
    public ItemLogin: ValidacaoUsuario= new ValidacaoUsuario();

    constructor(private router: Router, public autenticacaoSrv: AutenticacaoService, public _translate: TranslateService) {
       
    }



    public ngOnInit() {
        if (this.autenticacaoSrv.Logado)
        {
            this.autenticacaoSrv.ValidarToken().then(d=>
            {               
                if (d)
                    this.router.navigate(['home']);
            });

        }
        else
        {
            if (sessionStorage .getItem('TokenRegistImoveis'))
            {
                this.autenticacaoSrv.ValidarToken().then(d=>
                    {
                       
                        if (d)
                            this.router.navigate(['home']);
                    });
            }
           
        }
    }

   

    public Logar(): void {
        
            this.Erros = [];
            this.autenticacaoSrv.Logar (this.ItemLogin).then(resultado => {
               
                    this.TratarResultadoAutenticacao(resultado);
                });
        
    }

   
    
    private TratarResultadoAutenticacao(resultado: ResultadoOperacao<DadoAutenticacao>) {

        if (resultado.Sucesso) {


            this.autenticacaoSrv.CodigoUsuario = resultado.ItemRegistro.CodigoPessoa;
            this.autenticacaoSrv.IdentificadorEmpresa = resultado.ItemRegistro.IdentificadorEmpresa;
            this.autenticacaoSrv.Login = resultado.ItemRegistro.Login;
            this.autenticacaoSrv.TokenAutenticacao = resultado.ItemRegistro.Token;
            sessionStorage.setItem('TokenRegistImoveis', resultado.ItemRegistro.Token);
            this.autenticacaoSrv.NomeUsuario = resultado.ItemRegistro.NomePessoa;
            this.autenticacaoSrv.Logado = true;
            this.autenticacaoSrv.Acessos = resultado.ItemRegistro.Permissoes;
            this.router.navigate(['home']);

        }
        else {
            this.Erros = resultado.Mensagens;

        }
    }

    public ExistirMensagemComponente(NomeCampo: string): boolean {
        return this.Erros.some(d => d.Campo.some(e => e == NomeCampo));
    }

    public RetornarMensagensCampo(NomeCampo: string): string[] {
        return this.Erros.filter(d => d.Campo.some(e => e == NomeCampo)).map(d => d.Texto);
    }




}
