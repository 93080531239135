import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS
} from "@angular/common/http";
import { ConfigModule, ConfigService, ConfigLoader } from "@ngx-config/core";
import { ConfigHttpLoader } from "@ngx-config/http-loader";
import { LoadingScreenComponent } from "./shared/loading-screen/loading-screen.component";
import { LoadingScreenInterceptor } from "./services/loading.interceptor";
import { AutenticacaoInterceptor } from './services/autenticacao.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { NavComponent } from './nav/nav.component';
import localePT from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { TranslateModule } from './translate/translate.module';
import { TRANSLATION_PROVIDERS, TranslateService } from './translate';
import { BaseService } from './services/base.service';
import { AuthenticationGuard } from './shared/authentication.guard';
import { AlertifyService } from './services/alertify.service';
import { AutenticacaoService } from './services/autenticacao.service';
import { LoginComponent } from './login/login.component';
import { AlterarSenhaComponent } from './login/alterarsenha.component';
import { AssinadorService } from './services/assinador.service';

export function configFactory(http: HttpClient): ConfigLoader {
    return new ConfigHttpLoader(http, "./config.json"); // API ENDPOINT
}

registerLocaleData(localePT);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoadingScreenComponent,
        ErrorComponent,
        NavComponent,
        LoginComponent,
        AlterarSenhaComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ConfigModule,
        TranslateModule.forRoot(),
        ConfigModule.forRoot({
            provide: ConfigLoader,
            useFactory: configFactory,
            deps: [HttpClient]
        }),
        AppRoutingModule
    ],
    providers: [
        TRANSLATION_PROVIDERS,
        TranslateService,
        BaseService,
        AutenticacaoService,
        AuthenticationGuard,
        AlertifyService,
        AssinadorService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingScreenInterceptor,
            multi: true
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AutenticacaoInterceptor,
            multi: true
        }, { provide: LOCALE_ID, useValue: 'pt-br' }

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
