import { InjectionToken } from '@angular/core';

// import translations
import { LANG_PT_NAME, LANG_PT_TRANS, CALENDAR_PT } from './lang-pt';
import { LANG_EN_NAME, LANG_EN_TRANS, CALENDAR_EN } from './lang-en';

// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
export const dictionary = {
    [LANG_PT_NAME]: {
        Textos:LANG_PT_TRANS, Calendario:CALENDAR_PT
    },
    [LANG_EN_NAME]: {
        Textos: LANG_EN_TRANS, Calendario:CALENDAR_EN
    }
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];