export const LANG_EN_NAME = 'en-us';

export const LANG_EN_TRANS = {
    "SeparadorMilhar": ",",
    "SeparadorDecimal": ".",
    "FormatoDataCampo": "mm/dd/yy",
    "ErroAplicacao": "An error has ocurred in the application",
    "ErroAplicacao_Mensagem": "An error happened during your request. Please try again, and if it persists contact system's responsable.",
    "ErroAplicacao_Descricao": "Error description",
    "Pesquisa": "Filters",
    "Selecione": "Select",
    "Pesquisar": "Search",
    "Sim": "Yes",
    "Nao": "No",
    "Acao": "Action",
    "Novo": "New",
    "Salvar": "Save",
    "ValidarErro": "Alerts",
    "Cancelar": "Cancel",
    "Manutencao": "Maintenance",
    "Voltar": "Back",
};

export const CALENDAR_EN = {
    firstDayOfWeek: 1,
    dayNames: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
    dayNamesShort: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"],
    monthNames: ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
    monthNamesShort: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
}