import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from '../services/autenticacao.service'
import { TranslateService } from '../translate';
import { Router, ActivatedRoute } from '@angular/router';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity';
import { Mensagem} from '../entities/mensagem.entity';
import { AlteracaoSenha } from '../entities/alteracaosenha.entity';
import { AlertifyService } from '../services/alertify.service';

@Component({
    moduleId: module.id,
    templateUrl: './alterarsenha.component.html',
    styleUrls: ['./login.component.css']
})
export class AlterarSenhaComponent implements OnInit {


    public Erros: Mensagem[] = [];
    public ItemLogin: AlteracaoSenha= new AlteracaoSenha();

    constructor(private router: Router, public autenticacaoSrv: AutenticacaoService, public _translate: TranslateService,
        private alertify: AlertifyService,) {
       
    }



    public ngOnInit() {
        
    }

   

    public alterarSenha(): void {
        
            this.Erros = [];
            this.autenticacaoSrv.AlterarSenha (this.ItemLogin).then(resultado => {
               
                    this.TratarResultadoAutenticacao(resultado);
                });
        
    }

   
    
    private TratarResultadoAutenticacao(resultado: ResultadoOperacao<AlteracaoSenha>) {

        if (resultado.Sucesso) {
            this.alertify.sucess(resultado.Mensagens.map(d => d.Texto).join("<br/>"));

        }
        else {
            this.Erros = resultado.Mensagens;

        }
    }

    public ExistirMensagemComponente(NomeCampo: string): boolean {
        return this.Erros.some(d => d.Campo.some(e => e == NomeCampo));
    }

    public RetornarMensagensCampo(NomeCampo: string): string[] {
        return this.Erros.filter(d => d.Campo.some(e => e == NomeCampo)).map(d => d.Texto);
    }




}
