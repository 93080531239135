export enum enumTransacao {
    
  Usuario=1,
  Usuario_Incluir=2,
  Usuario_Alterar=3,
  Usuario_Excluir=4,
  Perfil=5,
  Perfil_Incluir=6,
  Perfil_Alterar=7,
  Perfil_Excluir=8,
  Empresa=9,
  Empresa_Incluir=10,
  Empresa_Alterar=11,
  Empresa_Excluir=12,
  Gerenciamento=13,
  LogAcesso=14,
  LogAlteracao=15,
  CapacidadeCivil=16,
  CapacidadeCivil_Incluir=17,
  CapacidadeCivil_Alterar=18,
  CapacidadeCivil_Excluir=19,
  Declaracao=20,
  Declaracao_Incluir=21,
  Declaracao_Alterar=22,
  Declaracao_Excluir=23,
  Cartorio=24,
  Cartorio_Incluir=25,
  Cartorio_Alterar=26,
  Cartorio_Excluir=27,
  EnquadramentoFinanciamento=28,
  EnquadramentoFinanciamento_Incluir=29,
  EnquadramentoFinanciamento_Alterar=30,
  EnquadramentoFinanciamento_Excluir=31,
  EspecieCCI=32,
  EspecieCCI_Incluir=33,
  EspecieCCI_Alterar=34,
  EspecieCCI_Excluir=35,
  EstadoCivil=36,
  EstadoCivil_Incluir=37,
  EstadoCivil_Alterar=38,
  EstadoCivil_Excluir=39,
  Genero=40,
  Genero_Incluir=41,
  Genero_Alterar=42,
  Genero_Excluir=43,
  Livro=44,
  Livro_Incluir=45,
  Livro_Alterar=46,
  Livro_Excluir=47,
  Localizacao=48,
  Localizacao_Incluir=49,
  Localizacao_Alterar=50,
  Localizacao_Excluir=51,
  Natureza=52,
  Natureza_Incluir=53,
  Natureza_Alterar=54,
  Natureza_Excluir=55,
  OrigemRecursos=56,
  OrigemRecursos_Incluir=57,
  OrigemRecursos_Alterar=58,
  OrigemRecursos_Excluir=59,
  Qualificacao=60,
  Qualificacao_Incluir=61,
  Qualificacao_Alterar=62,
  Qualificacao_Excluir=63,
  RegimeBens=64,
  RegimeBens_Incluir=65,
  RegimeBens_Alterar=66,
  RegimeBens_Excluir=67,
  SistemaAmortizacao=68,
  SistemaAmortizacao_Incluir=69,
  SistemaAmortizacao_Alterar=70,
  SistemaAmortizacao_Excluir=71,
  TipoAto=72,
  TipoAto_Incluir=73,
  TipoAto_Alterar=74,
  TipoAto_Excluir=75,
  TipoCCI=76,
  TipoCCI_Incluir=77,
  TipoCCI_Alterar=78,
  TipoCCI_Excluir=79,
  TipoImovel=80,
  TipoImovel_Incluir=81,
  TipoImovel_Alterar=82,
  TipoImovel_Excluir=83,
  TipoRegistro=84,
  TipoRegistro_Incluir=85,
  TipoRegistro_Alterar=86,
  TipoRegistro_Excluir=87,
  Cadastros=88,
  Empreendimento=89,
  Empreendimento_Incluir=90,
  Empreendimento_Alterar=91,
  Empreendimento_Excluir=92,
  Operacoes=93,
  Apresentante=94,
  Apresentante_Incluir=95,
  Apresentante_Alterar=96,
  Apresentante_Excluir=97,
  Contrato=98,
  Contrato_Incluir=99,
  Contrato_Alterar=100,
  Contrato_Excluir=101,
  Contrato_Transmitir=102,
  ConsultaMatricula=103,
  ConsultaMatricula_Incluir=104,
  ConsultaMatricula_Alterar=105,
  ConsultaMatricula_Excluir=106,
  Edificacao=107,
  Edificacao_Incluir=108,
  Edificacao_Alterar=109,
  Edificacao_Excluir=110,
}