export const LANG_PT_NAME = 'pt-br';

export const LANG_PT_TRANS = {
    "SeparadorMilhar":".",
    "SeparadorDecimal":",",
    "FormatoDataCampo":"dd/mm/yy",
    "ErroAplicacao" :"Houve um erro na aplicação",
    "ErroAplicacao_Mensagem":"Houve um erro ao processar sua requisiçãoo. Por favor tente novamente e se o problema persistir, contate um administrador.",
    "ErroAplicacao_Descricao": "Descrição do erro",
    "Pesquisa": "Filtros",
    "Selecione": "Selecione",
    "Pesquisar": "Pesquisar",
    "Sim": "Sim",
    "Nao": "Não",
    "Acao": "Ação",
    "Novo": "Novo",
    "Salvar": "Salvar",
    "ValidarErro": "Alertas",
    "Cancelar": "Cancelar",
    "Manutencao": "Manutenção",
    "Voltar":"Voltar",
    "Usuario":"Usuário",
    "Usuario_Plural":"Usuários",
    "ConfirmarExclusaoUsuario":"Confirma a exclusão do Usuário {0}?",
    "Perfil":"Perfil",
    "Perfil_Plural":"Perfis",
    "ConfirmarExclusaoPerfil":"Confirma a exclusão do Perfil {0}?",
    "Empresa":"Empresa",
    "Empresa_Plural":"Empresas",
    "ConfirmarExclusaoEmpresa":"Confirma a exclusão do Empresa {0}?",
    "Empresa_ChaveArisp":"Chave Arisp",
    "Empresa_CodigoParceiro":"Código Parceiro Arisp",
    "LogAcesso":"Log Acesso",
    "LogAcesso_Plural":"Log Acessos",
    "ConfirmarExclusaoLogAcesso":"Confirma a exclusão do Log Acesso {0}?",
    "Cartorio":"Cartório",
    "Cartorio_Plural":"Cartórios",
    "ConfirmarExclusaoCartorio":"Confirma a exclusão do Cartório {0}?",
    "EnquadramentoFinanciamento":"Enquadramento Financiamento",
    "EnquadramentoFinanciamento_Plural":"Enquadramentos Financiamento",
    "ConfirmarExclusaoEnquadramentoFinanciamento":"Confirma a exclusão do Enquadramento Financiamento {0}?",
    "Declaracao":"Declaração",
    "Declaracao_Plural":"Declarações",
    "ConfirmarExclusaoDeclaracao":"Confirma a exclusão do Declaração {0}?",
    "CapacidadeCivil":"Capacidade Civil",
    "CapacidadeCivil_Plural":"Capacidades Civil",
    "ConfirmarExclusaoCapacidadeCivil":"Confirma a exclusão do Capacidade Civil {0}?",
    "EspecieCCI":"Espécie CCI",
    "EspecieCCI_Plural":"Espécies CCI",
    "ConfirmarExclusaoEspecieCCI":"Confirma a exclusão do Espécie CCI {0}?",
    "EstadoCivil":"Estado Civil",
    "EstadoCivil_Plural":"Estados Civil",
    "ConfirmarExclusaoEstadoCivil":"Confirma a exclusão do Estado Civil {0}?",
    "Genero":"Gênero",
    "Genero_Plural":"Gêneros",
    "ConfirmarExclusaoGenero":"Confirma a exclusão do Gênero {0}?",
    "Livro":"Livro",
    "Livro_Plural":"Livros",
    "ConfirmarExclusaoLivro":"Confirma a exclusão do Livro {0}?",
    "Localizacao":"Localização",
    "Localizacao_Plural":"Localizações",
    "ConfirmarExclusaoLocalizacao":"Confirma a exclusão do Localização {0}?",
    "Natureza":"Natureza",
    "Natureza_Plural":"Naturezas",
    "ConfirmarExclusaoNatureza":"Confirma a exclusão do Natureza {0}?",
    "OrigemRecursos":"Origem Recursos",
    "OrigemRecursos_Plural":"Origens Recursos",
    "ConfirmarExclusaoOrigemRecursos":"Confirma a exclusão do Origem Recursos {0}?",
    "Qualificacao":"Qualificação",
    "Qualificacao_Plural":"Qualificações",
    "ConfirmarExclusaoQualificacao":"Confirma a exclusão do Qualificação {0}?",
    "RegimeBens":"Regime de Bens",
    "RegimeBens_Plural":"Regimes de Bens",
    "ConfirmarExclusaoRegimeBens":"Confirma a exclusão do Regime Bens {0}?",
    "SistemaAmortizacao":"Sistema de Amortização",
    "SistemaAmortizacao_Plural":"Sistemas de Amortização",
    "ConfirmarExclusaoSistemaAmortizacao":"Confirma a exclusão do Sistema Amortização {0}?",
    "TipoAto":"Tipo de Ato",
    "TipoAto_Plural":"Tipos de Ato",
    "ConfirmarExclusaoTipoAto":"Confirma a exclusão do Tipo Ato {0}?",
    "TipoCCI":"Tipo CCI",
    "TipoCCI_Plural":"Tipos CCI",
    "ConfirmarExclusaoTipoCCI":"Confirma a exclusão do Tipo CCI {0}?",
    "TipoImovel":"Tipo Imóvel",
    "TipoImovel_Plural":"Tipos de Imóveis",
    "ConfirmarExclusaoTipoImovel":"Confirma a exclusão do Tipo Imóvel {0}?",
    "TipoRegistro":"Tipo Registro",
    "TipoRegistro_Plural":"Tipos de Registro",
    "ConfirmarExclusaoTipoRegistro":"Confirma a exclusão do Tipo Registro {0}?",
    "NomeSistema":"Registro Imóveis",    
    "Descricao":"Descrição",
    "Codigo":"Código",
    "Ativo":"Ativo",
    "Login":"Login",
    "Senha":"Senha",
    "ConfirmarSenha":"Confirmar Senha",
    "Nome":"Nome",
    "Logar":"Logar",
    "AlterarSenha":"Alterar Senha",
    "Sair":"Sair",
    "SenhaAtual":"Senha Atual",
    "NovaSenha":"Nova Senha",
    "ConfirmacaoNovaSenha":"Confirmação Nova Senha",
    "Gerenciamento":"Gerenciamento",
    "LogAlteracao":"Log Alteração",
    "LogAcesso_DataAcessoDe":"Data Acesso De",
    "LogAcesso_DataAcessoAte":"Data Acesso Até",
    "LogAcesso_DataAcesso":"Data Acesso",
    "LogAcesso_Sucesso":"Sucesso",
    "LogAcesso_Usuario":"Nome Usuário",
    "LogAcesso_Mensagem":"Mensagem",
    "LogAcesso_IP":"IP",
    "LogAcesso_Erro":"Erro",
    "SemEmpresa":"Sem Empresa",
    "LogAlteracao_DataDe":"Data Ação De",
    "LogAlteracao_DataAte":"Data Ação Até",
    "LogAlteracao_Transacao":"Rotina",
    "LogAlteracao_Acao":"Ação",
    "LogAlteracao_DataAcao": "Data Ação",
    "LogAlteracao_Chave":"Chave",
    "Email":"E-mail",
    "Usuario_AlterarSenha":"Alterar Senha",
    "Usuario_ConfirmacaoSenha":"Confirmação Senha",
    "Usuario_Perfis":"Perfis",
    "UsuarioPerfil_Exclusao":"Confirma a remoção do perfil {0}?",
    "Adicionar":"Adicionar",
    "Perfil_Transacoes":"Rotinas",
    "Excluir":"Exclusão",
    "LogAlteracao_Chaves":"Chaves",
    "LogAlteracao_Pessoa":"Usuário",
    "LogAlteracao_TipoObjeto": "Objeto",
    "LogAlteracao_AcaoObjeto":"Ação Objeto",
    "LogAlteracao_FilhosModificados":"Filhos",
    "LogAlteracao_Campos":"Campos",
    "ValorAnterior": "Valor Anterior",
    "ValorAtual":"Valor Atual",
    "Declaracao_DeclaracaoAutorizacao":"Tipo",
    "Declaracao_Declaracao":"Declaração",
    "Declaracao_Autorizacao": "Autorização",
    "Comarca":"Comarca",
    "UF":"UF",
    "Cidade":"Cidade",
    "Pessoa_CNPJ":"CNPJ",
    "Pessoa_CPF":"CPF",
    "Pessoa_TipoPessoa": "Tipo Pessoa",
    "Pessoa_Documento":"Documento",
    "Pessoa_Fisica":"Física",
    "Pessoa_Juridica":"Jurídica",
    "EstadoCivil_PossuiConjuge": "Possui Cônjuge",
    "Pessoa_DDD":"DDD",
    "Pessoa_Telefone":"Telefone",
    "Pessoa_Genero":"Gênero",
    "Pessoa_EstadoCivil":"Estado Civil",
    "Pessoa_CapacidadeCivil":"Capacidade Civil",
    "Pessoa_RegimeBens":"Regime Bens",
    "Pessoa_MenorIdade":"Menor Idade",
    "Pessoa_DataNascimento":"Data Nascimento",
    "Pessoa_OrgaoEmissor":"Órgão Emissor",
    "Pessoa_Nacionalidade":"Nacionalidade",
    "Pessoa_NumeroPacto":"Número Pacto",
    "Pessoa_DataPacto":"Data Pacto",
    "Pessoa_LocalRegistroPacto":"Local Registro Pacto",
    "Pessoa_ConjugeAusente":"Cônjuge Ausente",
    "Pessoa_UniaoEstavel":"União Estável",
    "Pessoa_Profissao": "Profissão",
    "Pessoa_Filiacao1": "Filiação 1",
    "Pessoa_Filiacao2": "Filiação 2",
    "Pessoa_Conjuge":"Cônjuge",
    "Pessoa_DataCasamento":"Data Casamento",
    "Pessoa_Endereco":"Endereço",
    "PessoaEndereco_TipoLogradouro":"Tipo Logradouro",
    "PessoaEndereco_Logradouro":"Logradouro",
    "PessoaEndereco_Numero":"Número",
    "PessoaEndereco_Unidade":"Unidade",
    "PessoaEndereco_Bairro":"Bairro",
    "PessoaEndereco_Cidade":"Cidade",
    "PessoaEndereco_UF":"UF",
    "PessoaEndereco_CEP":"CEP",
    "Pessoa_Representantes":"Representantes",
    "PessoaRepresentante_Excluir":"Deseja excluir o Representante {0}?",
    "Representante_TipoRegistro":"Tipo Registro",
    "Representante_Numero":"Número",
    "Representante_Orgao":"Órgão",
    "Representante_FormaRegistro":"Forma Registro",
    "Representante_NumeroLivro":"Número Livro",
    "Representante_Folha":"Folha",
    "Representante_NumeroRegistro":"Número Registro",
    "Representante_DataRegistro":"Data Registro",
    "Representante":"Representante",
    "Representante_Nome":"Nome",
    "Representante_CPF":"CPF",
    "ErroInesperado":"Um erro inesperado ocorreu durante o processamento. Tente novamente em alguns segundos e se o erro persistir favor contactar o responsável pela aplicação.",
    "Qualificacao_Representante":"Qualificação de Representante",
    "Apresentante":"Apresentante",
    "Empreendimento":"Centro de Custo",
    "ConfirmarExclusaoEmpreendimento":"Confirma a exclusão do Centro de Custo {0}?",
    "ConfirmarExclusaoApresentante":"Confirma a exclusão do Apresentante {0}?",
    "Empreendimento_Plural":"Centros de Custo",
    "Apresentante_Plural":"Apresentantes",
    "Contrato":"Contrato",
    "Contrato_Plural":"Contratos",
    "ConfirmarExclusaoContrato":"Confirma a exclusão do Contrato {0}?",
    "Contrato_DataRegistroDe":"Data do Instrumento De",
    "Contrato_DataRegistroAte":"Data do Instrumento Até",
    "Contrato_DataRegistro":"Data do Instrumento",
    "Contrato_NumeroContrato":"Número",
    "Contrato_Situacao":"Situação",
    "Contrato_IdentificadorPessoaApresentante":"Apresentante",
    "Contrato_IdentificadorRepresentante":"Representante",
    "Contrato_Impostos":"Impostos",
    "Contrato_IsencaoITBI":"Isenção Imposto Transmissão",
    "Contrato_ValorITBI":"Valor Imposto Transmissão",
    "Contrato_NumeroInscricaoITBI":"Número Inscrição Imposto Transmissão",
    "Contrato_NumeroGuiaITBI": "Número Guia  Imposto Transmissão",
    "Contrato_DAJE":"DAJEs",
    "ContratoDAJE_Emissor":"Emissor",
    "ContratoDAJE_Serie":"Série",
    "ContratoDAJE_Numero":"Número",
    "ContratoDAJE_Valor":"Valor",
    "ContratoDAJE":"DAJE",
    "ContratoDAJE_Excluir":"Confirma a exclusão do DAJE {0}?",
    "Contrato_Declaracoes":"Declarações",
    "Contrato_PossuiCedula":"Possui Cédula",
    "Contrato_PossuiFinanciamento":"Possui Financiamento",
    "Contrato_Cedula":"Cédula",
    "Cedula_Numero":"Número",
    "Cedula_Tipo": "Tipo Cédula",
    "Cedula_Fracao":"Fração",
    "Cedula_Serie":"Série",
    "Cedula_CustodianteEmissor":"Custodiante Emissor",
    "Cedula_Data":"Data",
    "Financiamento_ValorFinanciamento":"Valor Financiamento",
    "Financiamento_ValorAvaliacao":"Valor Avaliação",
    "Financiamento_ValorLeilao": "Valor Leilão",
    "Financiamento_PrazoCarencia":"Prazo de Carência",
    "Financiamento_JurosAnualNominal":"Juros Anual Nominal",
    "Financiamento_JurosAnualEfetivo":"Juros Anual Efetivo",
    "Financiamento_JurosMensalNominal":"Juros Mensal Nominal",
    "Financiamento_JurosMensalEfetivo":"Juros Mensal Efetivo",
    "Financiamento_PrazoAmortizacao":"Prazo Amortização",
    "Financiamento_ValorPrimeiraParcela":"Valor Primeira Parcela",
    "Financiamento_DataPrimeiraParcela":"Data Primeira Parcela",
    "Financiamento_DestinoFinanciamento":"Destino Financiamento",
    "Financiamento_FormadePagamento":"Forma de Pagamento",
    "Financiamento_ValorTotalCredito":"Valor Total de Crédito",
    "Financiamento_TaxaMaximaJuros":"Taxa Máxima de Juros",
    "Financiamento_TaxaMinimaJuros":"Taxa Mínima de Juros",
    "Financiamento_PrazodeVigencia":"Prazo de Amortização",
    "Financiamento_ValorVencimentoAntecipado":"Valor Vencimento Antecipado",
    "Financiamento_InformacoesGerais":"Informações Gerais",
    "Contrato_Financiamento":"Financiamento",
    "Contrato_DadosContrato":"Dados Contrato",
    "Contrato_Negocios":"Negócios",
    "Negocio_ValorTransmissao":"Valor Transmissão",
    "Negocio_ValorVenal":"Valor Venal",
    "Negocio_ValorAvaliacao":"Valor Avaliação",
    "Negocio_ValorFinanciamento":"Valor Financiamento",
    "Negocio_ValorLeilao":"Valor Leilão",
    "Negocio_ValorRecursosProprios":"Valor Recursos Próprios",
    "Negocio_ValorRecursosFinanciados":"Valor Recursos Financiados",
    "Negocio_ValorOutrosRecursos":"Valor de Outros Recursos",
    "Negocio_ValorSubsidios":"Valor de Subsídios",
    "Negocio_ValorFGTS":"Valor do FGTS",    
    "Negocio_PrimeiraAquisicao":"Primeira Arquisição",
    "Negocio_ObservacoesGerais":"Observaçõe Gerais",
    "Negocio_Sequencial":"Sequencial",
    "Negocio_Imoveis":"Imóveis",
    "Negocio_Partes":"Partes",
    "Negocio_Excluir":"Confirma a exclusão do Negócio de Tipo {0}?",
    "Negocio":"Negócio",
    "Imovel":"Imóvel",
    "Imovel_Excluir":"Confirma a exclusão do Imóvel de Registro {0}?",
    "Imovel_NumeroRegistro":"Número de Matrícula/Registro",
    "Imovel_NumeroIPTU":"Número IPTU",
    "Imovel_CCIR":"CCIR",
    "Imovel_NIRF":"NIRF",
    "Imovel_Endereco":"Endereço",
    "Imovel_TipoLogradouro":"Tipo Logradouro",
    "Imovel_Logradouro":"Logradouro",
    "Imovel_Numero":"Número",
    "Imovel_Unidade":"Unidade",
    "Imovel_Bairro":"Bairro",
    "Imovel_Cidade":"Cidade",
    "Imovel_UF":"UF",
    "Imovel_Lote":"Lote",
    "Imovel_Quadra":"Quadra",
    "Imovel_Torre":"Torre",
    "Imovel_Loteamento":"Loteamento",
    "Imovel_Condominio":"Condomínio",
    "Imovel_Complemento":"Complemento",
    "Imovel_Verificacao":"Verificação",
    "ParteNegocio":"Parte do Negócio",
    "ParteNegocio_Excluir":"Confirma a exclusão da Parte do Negócio {0}?",
    "ParteNegocio_QualificacaoRepresentante":"Qualificação do Representante",
    "ParteNegocio_Fracao": "Fração",
    "ParteNegocio_Verificacao":"Verificação",
    "Contrato_Anexos": "Anexos",
    "ContratoAnexo_Tela":"Anexo do Contrato",
    "ContratoAnexo_Excluir":"Confirma a exclusão do Anexo {0}?",
    "ContratoAnexo_Descricao":"Descrição",
    "ContratoAnexo_Arquivo":"Arquivo",
    "ContratoAnexo_NomeArquivo":"Nome Arquivo",
    "ExtensaoNaoInstalada":"Extensão de assinatura não instalada",
    "Contrato_DetalheArisp":"Detalhe Pedido Arisp",
    "ArispDetalhePedido_IDPedido":"Código Pedido eProtocolo",
    "ArispDetalhePedido_Situacao":"Situação",
    "ArispDetalhePedido_Protocolo":"Protocolo",
    "ArispDetalhePedido_DataPedido":"Data Pedido",
    "ArispDetalhePedido_NumeroPrenotacao":"Número da Prenotação",
    "ArispDetalhePedido_DataPrenotacao":"Data da Prenotação",
    "ArispDetalhePedido_DataVencimentoPrenotacao":"Data de Vencimento da Prenotação",
    "ArispDetalhePedido_DataExameCalculo":"Data do Exame e Cálculo",
    "ArispDetalhePedido_ValorTaxaAdministrativa": "Valor da Taxa de Administração",
    "ArispDetalhePedido_ValorTaxaISS":"Valor da Taxa de ISS",
    "ArispDetalhePedido_ValorPrenotacao":"Valor da Prenotação",
    "ArispDetalhePedido_ValorTotal":"Valor Total",
    "ArispDetalhePedido_ValorRegistro":"Valor Registro",
    "ArispDetalhePedido_DataResposta":"Data da Resposta",
    "ArispDetalhePedido_ValorExameCalculo":"Valor do Exame e Cálculo",
    "ArispDetalhePedido_Resposta":"Respostas",
    "ArispDetalhePedido_AnexosAverbacao":"Arquivos de Averbacao",
    "ArispDetalhePedido_AnexosExigencia":"Notas de Exigências",
    "ArispDetalhePedido_AnexosBoletos":"Boletos",
    "ArispAnexo_URL":"URL",
    "ArispAnexo_Descricao":"Descrição",
    "ArispAnexo_Data":"Data",
    "Transmitir":"Transmitir",
    "ContratoTranmissao_Cidade":"Cidade",
    "ContratoTranmissao_UF":"UF",
    "ContratoTranmissao_Cartorio":"Cartorio",
    "ContratoTranmissao_Certificado":"Certificado",
    "ContratoTranmissao_TipoServico":"Tipo Serviço",
    "ContratoTranmissao_PIN":"PIN",
    "Contrato_CNS":"CNS",
    "Assinador_NativeNaoInstalado":"Assinador não Instalado",
    "Assinador_NativeDesatualizado":"Assinador Destualizado",
    "Extensao_NaoInstalada":"Extensão não Instalada",
    "ConsultaMatricula":"Consulta Matricula",
    "ConsultaMatricula_Plural":"Matrículas Consultadas",
    "ConfirmarExclusaoConsultaMatricula":"Confirma a exclusão do Consulta Matrícula {0}?",
    "ConsultaMatricula_CodigoUFArisp":"UF",
    "ConsultaMatricula_CodigoCidadeArisp":"Cidade",
    "ConsultaMatricula_CodigoCartorio":"Cartorio",
    "ConsultaMatricula_Matricula":"Matrícula",
    "ConsultaMatricula_SituacaoPedido":"Situação",
    "ConsultaMatricula_CodigoPedido":"Código Pedido",
    "ConsultaMatricula_Protocolo":"Protocolo",
    "ConsultaMatricula_PrazoEntrega": "Prazo Entrega",
    "ConsultaMatricula_CustoEmolumentos":"Custo Emolumentos",
    "ConsultaMatricula_CustoEstado":"Custo Estado",
    "ConsultaMatricula_CustoTaxaAdministracao":"Taxa de Administração",
    "ConsultaMatricula_CustoISS":"ISS",
    "ConsultaMatricula_CustoPedido":"Custo Pedido",
    "ConsultaMatricula_CustoTotal":"Custo Total",
    "ConsultaMatricula_DataPedido":"Data Pedido",
    "ConsultaMatricula_DataPedidoDe":"Data Pedido De",
    "ConsultaMatricula_DataPedidoAte":"Data Pedido Até",
    "ConsultaMatricula_MotivoDevolucao":"Motivo Devolução",
    "ConsultaMatricula_Arquivos":"Arquivos",
    "MatriculaArquivo_Nome":"Nome Arquivo",
    "Requisitar":"Requisitar",
    "Requisição":"Requisição",
    "Retorno":"Retorno",
    "Edificacao":"Edificação",
    "Edificacao_Plural":"Edificações",
    "ConfirmarExclusaoEdificacao":"Confirma a exclusão da Edificação {0}?"
};

export const CALENDAR_PT = {
    firstDayOfWeek: 0,
    dayNames: ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
    monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
}