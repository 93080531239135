import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticacaoService } from './services/autenticacao.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    constructor(
        private srvAutenticacao: AutenticacaoService,
        private route: ActivatedRoute,
        private router: Router
    )
    { }

  

    ngOnInit(): void {
        if (sessionStorage.getItem('TokenRegistImoveis')) {
            this.srvAutenticacao.CarregarDadoToken().then(d => {
                if (d && d.Sucesso) {
                    this.srvAutenticacao.Logado = true;
                    this.srvAutenticacao.CodigoUsuario = d.ItemRegistro.IdentificadorUsuario;
                    this.srvAutenticacao.NomeUsuario = d.ItemRegistro.NomePessoa;
                    this.srvAutenticacao.TokenAutenticacao = d.ItemRegistro.Token;
                    this.srvAutenticacao.Acessos = d.ItemRegistro.Permissoes;
                    this.srvAutenticacao.IdentificadorEmpresa = d.ItemRegistro.IdentificadorEmpresa;

                }
                else {
                    sessionStorage.removeItem("TokenRegistImoveis");
                    this.router.navigate(["login"]);
                }
            });

        }
        else
            this.router.navigate(["login"]);
    }
}
