import { Component, OnInit } from "@angular/core";
import { AutenticacaoService } from '../services/autenticacao.service';

@Component({
  templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit {
  constructor(private authServ: AutenticacaoService) {}
  ngOnInit() {
  
  }

 
}
