import { Injectable } from "@angular/core";
declare let alertify: any;

@Injectable({
  providedIn: "root"
})
export class AlertifyService {
  constructor() {
    alertify.defaults = {
      // dialogs defaults
      autoReset:true,
      basic:false,
      closable:true,
      closableByDimmer:true,
      frameless:false,
      maintainFocus:true, // <== global default not per instance, applies to all dialogs
      maximizable:true,
      modal:true,
      movable:false,
      moveBounded:false,
      overflow:true,
      padding: true,
      pinnable:true,
      pinned:true,
      preventBodyShift:false, // <== global default not per instance, applies to all dialogs
      resizable:true,
      startMaximized:false,
      transition:'pulse',

      // notifier defaults
      notifier:{
          // auto-dismiss wait time (in seconds)
          delay:10,
          // default position
          position:'bottom-right',
          // adds a close button to notifier messages
          closeButton: false
      },

      // language resources
      glossary:{
          // ok button text
          ok: 'Confirmar',
          // cancel button text
          cancel: 'Cancelar'
      },

      // theme settings
      theme:{
          // class name attached to prompt dialog input textbox.
          input:'form-control',
          // class name attached to ok button
          ok:'btn btn-primary btn-sm',
          // class name attached to cancel button
          cancel:'btn btn-danger btn-sm'
      },
      hooks:{
        // triggered when the dialog is shown, this is seperate from user defined onshow
        onshow: function(){
        },
        // triggered when the dialog is closed, this is seperate from user defined onclose
        onclose: function(){
        },
        // triggered when a dialog option gets updated.
        // IMPORTANT: This will not be triggered for dialog custom settings updates ( use settingUpdated instead).
        onupdate: function(){
        }
     }
  };
  }
  confirm(title: string, message: string, okCallback: () => any, cancelCallBack:() => any) {
    alertify.confirm(title, message, function(e) {
      if (e) {

        okCallback();
      } else {
      }
    }, function(e){

      cancelCallBack();
    });
  }

  sucess(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
