import { Injectable } from '@angular/core';
import { EventManager, Meta } from '@angular/platform-browser';
import { TranslateService } from '../translate';
import { ConfigService } from '@ngx-config/core';
import { AutenticacaoService } from './autenticacao.service';

@Injectable()
export class AssinadorService {

    private isIE = null;
    private isChrome = null;
    private isFirefox = null;
    private isEdge = null;
    private isSafari = null;
    private responseEventName: string = "com.efluxus.certificado.ResponseEvent"
    private requestEventName: string = "com.efluxus.certificado.RequestEvent"
    private pendingRequests = {};

    constructor(private event: EventManager, private meta: Meta, private _translate: TranslateService,
         private readonly autenticacaoService: AutenticacaoService) {
        let versaoBrowser = this.RetornarBrowser();
        this.isIE = (versaoBrowser.indexOf('IE') >= 0);
        this.isChrome = (versaoBrowser.indexOf('Chrome') >= 0);
        this.isFirefox = (versaoBrowser.indexOf('Firefox') >= 0);
        this.isEdge = (versaoBrowser.indexOf('Edge') >= 0);
        this.isSafari = (versaoBrowser.indexOf('Safari') >= 0);
        let obj = this;
        if (this.isChrome || this.isEdge) {
            document.addEventListener(this.responseEventName, function (event: CustomEvent) {

                obj.onResponseReceived(event.detail);
            });
        } else {
            window.addEventListener('message', function (event) {
                if (event && event.data && event.data.port === obj.responseEventName) {
                    obj.onResponseReceived(event.data.message);
                }
            });
        }
    }

    public VerificarExtensao(): boolean
    {
        return this.meta.getTags("id='com.efluxus.certificado'").length > 0;;
    }

    public VerificarAssinador(): Promise<string>
    {
        var obj = this;
        return new Promise<string>(function (resolve, reject) {
            if (obj.VerificarExtensao()) {
                var message = { Acao: "RetornarVersao" };
                obj.EnviarMensagem(message, function (resultado) {
                    if (resultado.Sucesso)
                        {
                            obj.autenticacaoService.RetornarConfiguracaoAssinador().subscribe(
                                d=>
                                { 
                                    if (!resultado.Versao || resultado.Versao != d.VersaoEsperada)
                                    {
                                        reject(obj._translate.instant("Assinador_NativeDesatualizado"));

                                    }
                                    else
                                    {
                                        resolve("");
                                    }

                                }
                            );                                                        
                        }
                    else
                        return reject(resultado.MensagemErro)
                });
            }
            else {
                return reject(obj._translate.instant("ExtensaoNaoInstalada"));
            }
        });

    }


    

    public RetornarCertificados(): Promise<string[]> {
        var obj = this;
        return new Promise<string[]>(function (resolve, reject) {
            if (obj.VerificarExtensao()) {
                var message = { Acao: "Listar" };
                obj.EnviarMensagem(message, function (resultado) {
                    if (resultado.Sucesso)
                        return resolve(resultado.Certificados);
                    else
                        return reject(resultado.MensagemErro)
                });
            }
            else {
                return reject(obj._translate.instant("ExtensaoNaoInstalada"));
            }
        });

    }

    public AssinarXML(TextoXML: string, Pin: string, NomeCertificado: string): Promise<string> {
        var obj = this;
        return new Promise<string>(function (resolve, reject) {
            if (obj.VerificarExtensao()) {
                var message = { Acao: "Assinar", XML: TextoXML, Pin: Pin, NomeCertificado: NomeCertificado };
                obj.EnviarMensagem(message, function (resultado) {
                    if (resultado.Sucesso)
                        return resolve(resultado.XML);
                    else
                        return reject(resultado.MensagemErro)
                });
            }
            else {
                return reject(obj._translate.instant("ExtensaoNaoInstalada"));
            }
        });

    }

    public EnviarMensagem(message: any, promise: Function) {

        var requestId = this.registerPromise(promise);
        message.requestId = requestId;
        if (this.isChrome) {
            var eventC = new CustomEvent('build', { 'detail': message });
            eventC.initEvent(this.requestEventName);
            document.dispatchEvent(eventC);
        } else if (this.isEdge) {
            var eventE = new CustomEvent(this.requestEventName, { 'detail': message });
            document.dispatchEvent(eventE);
        } else {
            window.postMessage({
                port: this.requestEventName,
                message: message
            }, "*");
        }
    }

    private onResponseReceived(result: any) {
        console.log(result);
        if (result.success === false && result.exception)
        {
            var chaves = Object.keys(this.pendingRequests);
            var requestId:any = chaves.pop();
            var request = this.pendingRequests[requestId];
            delete this.pendingRequests[requestId];

            var resultado = {Sucesso:false, MensagemErro:this._translate.instant("Assinador_NativeNaoInstalado")};
            request(resultado);

        }
        else
        {
            var request = this.pendingRequests[result.requestId];
            delete this.pendingRequests[result.requestId];
            if (request)
                request(result);
        }
    }

    private s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };

    private generateGuid = function () {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
    };


    private registerPromise(promise: Function) {
        var requestId = this.generateGuid();
        this.pendingRequests[requestId] = promise
        return requestId;
    };

    private RetornarBrowser(): string {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    }
}