import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './shared/authentication.guard'
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { AlterarSenhaComponent } from './login/alterarsenha.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'login', component: LoginComponent},
    { path: 'alterarsenha', component: AlterarSenhaComponent},

    { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
    { path: 'error', component: ErrorComponent },                    
    {
        path: 'gerenciamento/usuario', 
        loadChildren: './gerenciamento/usuario/usuario.module#UsuarioModule'
    },                    
    {
        path: 'gerenciamento/perfil', 
        loadChildren: './gerenciamento/perfil/perfil.module#PerfilModule'
    },                    
    {
        path: 'gerenciamento/empresa', 
        loadChildren: './gerenciamento/empresa/empresa.module#EmpresaModule'
    },                    
    {
        path: 'gerenciamento/logacesso', 
        loadChildren: './gerenciamento/logacesso/logacesso.module#LogAcessoModule'
    },   
    {
        path: 'gerenciamento/logalteracao', 
        loadChildren: './gerenciamento/logalteracao/logalteracao.module#LogAlteracaoModule'
    },                    
    {
        path: 'cadastros/cartorio', 
        loadChildren: './cadastros/cartorio/cartorio.module#CartorioModule'
    },                    
    {
        path: 'cadastros/enquadramentofinanciamento', 
        loadChildren: './cadastros/enquadramentofinanciamento/enquadramentofinanciamento.module#EnquadramentoFinanciamentoModule'
    },                    
    {
        path: 'cadastros/declaracao', 
        loadChildren: './cadastros/declaracao/declaracao.module#DeclaracaoModule'
    },                    
    {
        path: 'cadastros/capacidadecivil', 
        loadChildren: './cadastros/capacidadecivil/capacidadecivil.module#CapacidadeCivilModule'
    },                    
    {
        path: 'cadastros/especiecci', 
        loadChildren: './cadastros/especiecci/especiecci.module#EspecieCCIModule'
    },                    
    {
        path: 'cadastros/estadocivil', 
        loadChildren: './cadastros/estadocivil/estadocivil.module#EstadoCivilModule'
    },                    
    {
        path: 'cadastros/genero', 
        loadChildren: './cadastros/genero/genero.module#GeneroModule'
    },                    
    {
        path: 'cadastros/livro', 
        loadChildren: './cadastros/livro/livro.module#LivroModule'
    },                    
    {
        path: 'cadastros/localizacao', 
        loadChildren: './cadastros/localizacao/localizacao.module#LocalizacaoModule'
    },                    
    {
        path: 'cadastros/natureza', 
        loadChildren: './cadastros/natureza/natureza.module#NaturezaModule'
    },                    
    {
        path: 'cadastros/origemrecursos', 
        loadChildren: './cadastros/origemrecursos/origemrecursos.module#OrigemRecursosModule'
    },                    
    {
        path: 'cadastros/qualificacao', 
        loadChildren: './cadastros/qualificacao/qualificacao.module#QualificacaoModule'
    },                    
    {
        path: 'cadastros/regimebens', 
        loadChildren: './cadastros/regimebens/regimebens.module#RegimeBensModule'
    },                    
    {
        path: 'cadastros/sistemaamortizacao', 
        loadChildren: './cadastros/sistemaamortizacao/sistemaamortizacao.module#SistemaAmortizacaoModule'
    },                    
    {
        path: 'cadastros/tipoato', 
        loadChildren: './cadastros/tipoato/tipoato.module#TipoAtoModule'
    },                    
    {
        path: 'cadastros/tipocci', 
        loadChildren: './cadastros/tipocci/tipocci.module#TipoCCIModule'
    },                    
    {
        path: 'cadastros/tipoimovel', 
        loadChildren: './cadastros/tipoimovel/tipoimovel.module#TipoImovelModule'
    },                    
    {
        path: 'cadastros/tiporegistro', 
        loadChildren: './cadastros/tiporegistro/tiporegistro.module#TipoRegistroModule'
    },                    
    {
        path: 'cadastros/empreendimento', 
        loadChildren: './cadastros/empreendimento/empreendimento.module#EmpreendimentoModule'
    },                    
    {
        path: 'cadastros/apresentante', 
        loadChildren: './cadastros/apresentante/apresentante.module#ApresentanteModule'
    },                    
    {
        path: 'operacao/contrato', 
        loadChildren: './operacao/contrato/contrato.module#ContratoModule'
    },                    
    {
        path: 'operacao/consultamatricula', 
        loadChildren: './operacao/consultamatricula/consultamatricula.module#ConsultaMatriculaModule'
    },                    
    {
        path: 'cadastros/edificacao', 
        loadChildren: './cadastros/edificacao/edificacao.module#EdificacaoModule'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
