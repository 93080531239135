import { Injectable, EventEmitter } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '../translate';
import { BaseService } from './base.service';
import { ResultadoOperacao } from '../entities/resultadooperacao.entity';
import { Observable } from 'rxjs';
import { Mensagem } from '../entities/mensagem.entity';
import { DadoAutenticacao } from '../entities/dadoautenticacao.entity';
import { ValidacaoUsuario } from '../entities/validacaousuario.entity';
import { AlteracaoSenha } from '../entities/alteracaosenha.entity';
import { enumTransacao } from '../domains/enumtransacao.enum';
import { ItemLista } from '../entities/itemlista.entity';
import { Transacao } from '../entities/transacao.entity';
import { ConfiguracaoAssinador } from '../entities/configuracaoassinador.entity';
import { map } from 'rxjs/operators';

@Injectable()

export class AutenticacaoService extends BaseService {



    public IdentificadorEmpresa: number;
    public Acessos: number[] = [];
    public CodigoUsuario: number;
    public NomeUsuario: string;
    public TokenAutenticacao: string;
    public Logado: boolean = false;
    public MensagemAlerta: string = null;
    public Login: string = null;
    public Carregando: boolean = false;
    public Erros: Mensagem[] = [];


    constructor(private http: HttpClient, readonly config: ConfigService) {
        super(config)
        this.apiUrl += 'Autenticacao';
    }

    ValidarToken(): Promise<boolean> {
        var Token = sessionStorage.getItem("TokenRegistImoveis");
        return this.http.get(this.apiUrl + "/ValidarToken?Token=" + Token)
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
     };


    CarregarDadoToken(): Promise<ResultadoOperacao<DadoAutenticacao>>
     {
         var url: string = this.apiUrl + "/CarregarDadoToken?CarregarTransacao=true";
         return this.http.get( url)
             .toPromise()
             .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
     }   
  

    LogOut(): Promise<void> {
        var url: string = this.apiUrl + "/Logout";
        return this.http.get(url)
            .toPromise()
            .then(Response => { });
    }

    Logar(itemValidacao: ValidacaoUsuario): Promise<ResultadoOperacao<DadoAutenticacao>> {

        var url: string = this.apiUrl + "/Login";
        return this.http.post( url,itemValidacao)
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));
     };
   
     AlterarSenha(itemAlteracaoSenha: AlteracaoSenha): Promise<ResultadoOperacao<AlteracaoSenha>>{
        var url: string = this.apiUrl + "/AlterarSenha";
        return this.http.post( url,itemAlteracaoSenha)
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));

     }

     ListarTrasacao(Estruturada: boolean, ApenasTela: boolean, PorEmpresa: boolean): Promise<Transacao[]> {
        var url: string = this.apiUrl + "/ListarTrasacao/" + Estruturada + "/" + ApenasTela;
        if (PorEmpresa != null && PorEmpresa != undefined)
            url += "?PorEmpresa=" + PorEmpresa.toString();
        return this.http.get(url)
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
    };

    ListarEnumTipoAcao(CodigosIgnorar: number[]): Promise<ItemLista[]>
    {
        let params = new HttpParams();
        var url: string = this.apiUrl  + "/ListarEnumTipoAcao"
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
        CodigosIgnorar.forEach(d=>{
        params = params.append("CodigosIgnorar", d.toString())
        });

       
        
        return this.http.get( url,{params: params})
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));
    }

    ListarEnumSituacao(CodigosIgnorar: number[]): Promise<ItemLista[]>
    {
        let params = new HttpParams();
        var url: string = this.apiUrl  + "/ListarEnumSituacao"
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
        CodigosIgnorar.forEach(d=>{
        params = params.append("CodigosIgnorar", d.toString())
        });

       
        
        return this.http.get( url,{params: params})
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));
    }

    ListarenumTipoServicoArisp(CodigosIgnorar: number[]): Promise<ItemLista[]>
    {
        let params = new HttpParams();
        var url: string = this.apiUrl  + "/ListarenumTipoServicoArisp"
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
        CodigosIgnorar.forEach(d=>{
        params = params.append("CodigosIgnorar", d.toString())
        });

       
        
        return this.http.get( url,{params: params})
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));
    }

    public RetornarConfiguracaoAssinador(): Observable<ConfiguracaoAssinador>
    {
        var url: string = this.apiUrl + '/RetornarConfiguracaoAssinador';
        return this.http.get<ConfiguracaoAssinador>(url).pipe(
            map(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora))
        )
    }

    ListarenumSituacaoPedido(CodigosIgnorar: number[]): Promise<ItemLista[]>
    {
        let params = new HttpParams();
        var url: string = this.apiUrl  + "/ListarenumSituacaoPedido"
        if (CodigosIgnorar && CodigosIgnorar.length > 0)
        CodigosIgnorar.forEach(d=>{
        params = params.append("CodigosIgnorar", d.toString())
        });

       
        
        return this.http.get( url,{params: params})
            .toPromise()
            .then(response => JSON.parse(JSON.stringify(response), this.AjustarDataHora));
    }
}